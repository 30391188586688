"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUBSCRIPTION_CHANGED = exports.PERMISSION_CHANGED = exports.IN_APP_MESSAGE_DID_DISMISS = exports.IN_APP_MESSAGE_WILL_DISMISS = exports.IN_APP_MESSAGE_DID_DISPLAY = exports.IN_APP_MESSAGE_WILL_DISPLAY = exports.IN_APP_MESSAGE_CLICKED = exports.NOTIFICATION_CLICKED = exports.NOTIFICATION_WILL_DISPLAY = void 0;
exports.NOTIFICATION_WILL_DISPLAY = 'OneSignal-notificationWillDisplayInForeground';
exports.NOTIFICATION_CLICKED = 'OneSignal-notificationClicked';
exports.IN_APP_MESSAGE_CLICKED = 'OneSignal-inAppMessageClicked';
exports.IN_APP_MESSAGE_WILL_DISPLAY = 'OneSignal-inAppMessageWillDisplay';
exports.IN_APP_MESSAGE_DID_DISPLAY = 'OneSignal-inAppMessageDidDisplay';
exports.IN_APP_MESSAGE_WILL_DISMISS = 'OneSignal-inAppMessageWillDismiss';
exports.IN_APP_MESSAGE_DID_DISMISS = 'OneSignal-inAppMessageDidDismiss';
exports.PERMISSION_CHANGED = 'OneSignal-permissionChanged';
exports.SUBSCRIPTION_CHANGED = 'OneSignal-subscriptionChanged';
